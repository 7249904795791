import Elm from './src/Main'
import firebase, { firestore } from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import './assets/style.scss'
import vhcheck from 'vh-check'

// Google Analytics
declare var gtag: any

vhcheck()

const homeUrl = 'https://shogix.jp/'

switch (process.env.NODE_ENV) {
  case 'development':
    firebase.initializeApp(
      {
        apiKey: "AIzaSyBKucDRdC-PyHh48b9cIlv1jFw6H7m1Puc",
        authDomain: "shogix-development.firebaseapp.com",
        databaseURL: "https://shogix-development.firebaseio.com",
        projectId: "shogix-development",
        storageBucket: "shogix-development.appspot.com",
        messagingSenderId: "1013444544382"
      }
    )
    break
  case 'production':
    firebase.initializeApp(
      {
        apiKey: "AIzaSyB54V9AHWmouepTlGtfvl0i0QKM_9TjuUg",
        authDomain: "shogix.firebaseapp.com",
        databaseURL: "https://shogix.firebaseio.com",
        projectId: "shogix",
        storageBucket: "shogix.appspot.com",
        messagingSenderId: "415603155659"
      }
    )
    break
}


/*
 * Firebase Functions
 */
const functions = firebase.app().functions('asia-northeast1')
const createGame = functions.httpsCallable('createGame')
const createMatchmaking = functions.httpsCallable('createMatchmaking')
const cancelMatchmaking = functions.httpsCallable('cancelMatchmaking')
const cancelGame = functions.httpsCallable('cancelGame')
const entryGame = functions.httpsCallable('entryGame')
const openGame = functions.httpsCallable('openGame')
const closeGame = functions.httpsCallable('closeGame')
const offlineGame = functions.httpsCallable('offlineGame')
const movePiece = functions.httpsCallable('movePiece')

/*
 * Firestore
 */
let _unsubscribeUser = null
let _unsubscribeGame = null

function subscribeUser(rowUser: firebase.User) {
  if (_unsubscribeUser) {
    unsubscribeUser()
  }

  /*
  firebase
    .auth()
    .currentUser
    .getIdToken()
    .then(function (token) {
      // console.log('token', token)
    })
  */

  const updateData = toUser(rowUser)

  const userRef = firebase
    .firestore()
    .collection('users')
    .doc(rowUser.uid)

  _unsubscribeUser = userRef
    .onSnapshot(user => {
      const userData = Object.assign(updateData, user.data() || {})
      // console.log('subscribeUserData', user.data())
      if (user.get('state') !== 'online') {
        userOnline(rowUser.uid)
      }
      app.ports.updateUser.send(userData)

      const gameId = user.get('currentGameId')
      if (gameId) {
        subscribeGame(gameId)
      }
    })
}

function unsubscribeUser() {
  if (_unsubscribeUser) {
    _unsubscribeUser()
  }

  _unsubscribeUser = null
  app.ports.updateGuest.send(null)
}

function subscribeGame(gameId: string) {
  // console.log('subscribeGameId', gameId)

  if (_unsubscribeGame) {
    unsubscribeGame()
  }

  _unsubscribeGame = firebase
    .firestore()
    .collection('games')
    .doc(gameId)
    .onSnapshot(game => {
      // console.log('subscribeGame', game.data())
      if (!game.data()) {
        // console.log('game not found!')
        // TODO: エラーを通知
      }

      const users_ = game.get('users')
      const users = { black: null, white: null }
      for (const uid in users_) {
        const user = users_[uid]
        users[user.color] = {
          uid,
          name: user.name,
          state: user.state,
          stateAt: user.stateAt.toMillis()
        }
      }
      // console.log(users)

      const data = game.data()
      if (!data.uid) {
        data.uid = null
      }
      data.users = users
      data.createdAt = data.createdAt.toMillis()
      data.entriedAt = data.entriedAt ? data.entriedAt.toMillis() : null
      data.openedAt = data.openedAt ? data.openedAt.toMillis() : null
      data.closedAt = data.closedAt ? data.closedAt.toMillis() : null
      // console.log('subscribeGame:formatted', data)

      app.ports.updateGame.send(data)
    })
}

function unsubscribeGame() {
  // console.log('unsubscribeGame')

  if (_unsubscribeGame) {
    _unsubscribeGame()
  }

  _unsubscribeGame = null
}

/*
 * User Presence
 */
let databaseUsersRef = null;

const databaseOnline = {
  state: 'online',
  stateAt: firebase.database.ServerValue.TIMESTAMP
}

const databaseOffline = {
  state: 'offline',
  stateAt: firebase.database.ServerValue.TIMESTAMP
}

function userOnline(uid: string) {
  const _databaseUsersRef = firebase.database().ref(`/users/${uid}`)
  if (databaseUsersRef && databaseUsersRef.key !== _databaseUsersRef.key) {
    userOffline()
  }

  databaseUsersRef = _databaseUsersRef
  databaseUsersRef.onDisconnect().set(databaseOffline)
  databaseUsersRef.set(databaseOnline)
}

function userOffline() {
  if (databaseUsersRef) {
    databaseUsersRef.set(databaseOffline)
    databaseUsersRef.onDisconnect().cancel()
  }

  databaseUsersRef = null
}

firebase.database().ref('.info/connected').on('value', snapshot => {
  if (firebase.auth().currentUser && snapshot.val()) {
    userOnline(firebase.auth().currentUser.uid)
  } else {
    userOffline()
  }
})

/*
 * Elm
 */
const app = Elm.Main.init()

function toUser(rowUser: firebase.User) {
  return {
    uid: rowUser.uid,
    name: rowUser.displayName || '',
    email: rowUser.email,
    currentGameId: null,
    currentMatchmakingId: null,
    state: null
  }
}

firebase.auth().onAuthStateChanged(rowUser => {
  if (rowUser) {
    subscribeUser(rowUser)
  } else {
    unsubscribeUser()
  }
})

app.ports.subscribeGame.subscribe((gameId: string) => {
  subscribeGame(gameId)
})

app.ports.unsubscribeGame.subscribe(() => {
  unsubscribeGame()
})

app.ports.createUser.subscribe((data: string[]) => {
  const name = data[0], email = data[1], password = data[2]

  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(userCredential => {
      return userCredential.user.updateProfile({ displayName: name })
    })
    .then(() => {
      gtag('event', 'sign_up', { method: 'Email' })

      const rowUser = firebase.auth().currentUser
      app.ports.createUserDone.send(toUser(rowUser))
    })
    .catch(err => {
      // console.log(err)
      app.ports.createUserErr.send(err)
    })
})

app.ports.signIn.subscribe((data: string[]) => {
  const email = data[0], password = data[1]

  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(() => {
      gtag('event', 'login', { method: 'Email' })

      const rowUser = firebase.auth().currentUser
      app.ports.signInDone.send(toUser(rowUser))
    })
    .catch(err => {
      // console.log(err)
      app.ports.signInErr.send(err)
    })
})

app.ports.signOut.subscribe(() => {
  userOffline()
  firebase.auth().signOut()
})

app.ports.resetPassword.subscribe((data: string) => {
  const email = data

  firebase
    .auth()
    .sendPasswordResetEmail(email, { url: homeUrl })
    .then(() => {
      app.ports.resetPasswordDone.send(null)
    })
    .catch(err => {
      app.ports.resetPasswordErr.send(err)
    })
})

app.ports.updatePassword.subscribe((data: string[]) => {
  const email = data[0], currentPassword = data[1], newPassword = data[2]
  const user = firebase.auth().currentUser

  firebase
    .auth()
    .signInWithEmailAndPassword(email, currentPassword)
    .then(() => {
      user
        .updatePassword(newPassword)
        .then(() => {
          app.ports.updatePasswordDone.send(null)
        })
    })
    .catch(err => {
      app.ports.updatePasswordErr.send(err)
    })
})

app.ports.updateProfile.subscribe((data: string[]) => {
  const name = data[0], email = data[1]

  const user = firebase.auth().currentUser
  const batch = []

  if (user.displayName !== name) {
    batch.push(user.updateProfile({ displayName: name }))
  }

  if (user.email !== email) {
    batch.push(user.updateEmail(email))
  }

  Promise
    .all(batch)
    .then(() => {
      const rowUser = firebase.auth().currentUser
      app.ports.updateProfileDone.send(toUser(rowUser))
    })
    .catch(err => {
      app.ports.updateProfileErr.send(err)
    })
})

app.ports.createMatchmaking.subscribe((data: { [key: string]: number }) => {
  // console.log('createMatchmaking', data)
  createMatchmaking(data)
    .then(result => {
      app.ports.createMatchmakingDone.send(result.data)
      // console.log(result)
    })
})

app.ports.cancelMatchmaking.subscribe((data: undefined) => {
  // console.log('cancelMatchmaking')
  cancelMatchmaking(data)
    .then(result => {
      app.ports.cancelMatchmakingDone.send(null)
      // console.log(result)
    })
})

app.ports.fetchMatchmaking.subscribe((matchmakingId: string) => {
  const matchmakingRef = firestore().collection('matchmakings').doc(matchmakingId)
  return matchmakingRef
    .get()
    .then(doc => {
      if (!doc.exists || !matchmakingId) {
        return
      }

      const matchmaking = {
        uid: doc.get('uid'),
        name: doc.get('name'),
        rule: doc.get('rule'),
        createdAt: doc.get('createdAt').toMillis()
      }
      app.ports.fetchMatchmakingDone.send(matchmaking)
    })
})

app.ports.createGame.subscribe((data: { [key: string]: number }) => {
  // console.log('createGame', data)
  createGame(data)
    .then(result => {
      app.ports.createGameDone.send(result.data)
      // console.log(result)
    })
})

app.ports.cancelGame.subscribe((data: undefined) => {
  // console.log('cancelGame')
  cancelGame(data)
    .then(result => {
      app.ports.cancelGameDone.send(null)
      // console.log(result)
    })
})

app.ports.entryGame.subscribe((gameId: string) => {
  // console.log('entryGame', gameId)
  entryGame(gameId)
})

app.ports.openGame.subscribe((data: [string, number]) => {
  // console.log('openGame', data)
  openGame(data)
})

app.ports.closeGame.subscribe((data: [string, number, string]) => {
  // console.log('closeGame', data)
  closeGame(data)
})

app.ports.offlineGame.subscribe((data: [string, number, string]) => {
  // console.log('offlineGame', data)
  offlineGame(data)
})

app.ports.movePiece.subscribe((data: [string, number, string]) => {
  // console.log('movePiece', data)
  movePiece(data)
})

app.ports.copy.subscribe((id: string) => {
  const elm = document.getElementById(id)
  const range = document.createRange()
  range.selectNode(elm)
  window.getSelection().addRange(range)
  document.execCommand('copy')
})

app.ports.pageview.subscribe((data: [string, string | null]) => {
  // console.log(data)

  const page_path = data[0]
  const user_id = data[1]
  const linker = { 'domains': ['shogix.jp', 'about.shogix.jp'] }

  if (user_id) {
    gtag('config', 'UA-140349996-1', { linker, page_path, user_id })
  } else {
    gtag('config', 'UA-140349996-1', { linker, page_path })
  }
})